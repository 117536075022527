import React from 'react';
import './opponent-ship-status.css';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const OpponentShips = ({ SHIPS, opponentGrid }) => (
    <table className="opponent-ships-table">
    <thead>
        <tr>
            <th>Ship ID</th>
            <th>Status</th>
            <th>Detail</th>
        </tr>
    </thead>
    <tbody>
        {SHIPS.map(ship => {
            const hits = opponentGrid.filter(x => x === ship.id).length;
            const isDestroyed = hits === 0;

            return (
                <tr key={ship.id} className="opponent-ship-row">
                    <td className="ship-name">Ship {ship.id}</td>
                    <td className="ship-status">
                        {Array.from({ length: ship.size }).map((_, i) => (
                            <span
                                key={i}
                                className={i < hits ? "undestroyed" : "destroyed"}
                            >
                                {i < hits ? <RadioButtonUncheckedIcon /> : <CancelIcon />}
                            </span>
                        ))}
                    </td>
                    <td className={`ship-status-text ${isDestroyed ? 'destroyed-text' : 'not-destroyed-text'}`}>
                        {isDestroyed ? 'Destroyed' : 'Not Destroyed'}
                    </td>
                </tr>
            );
        })}
    </tbody>
</table>
);

export default OpponentShips;
