import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, IconButton,DialogContentText } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

function WinnerDialog({ open, onClose, winner, onReset }) {
  return (
    <Dialog
    open={open}
    onClose={onReset}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      {"Game Result"}
      <IconButton edge="end" color="inherit" onClick={onReset} aria-label="close">
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
       {winner }
      </DialogContentText>
    </DialogContent>
  </Dialog>
  );
}

export default WinnerDialog;