import {createContext, useState} from "react";

export const GameModeContext = createContext('');
export const GameModeProvider =  ({children}) => {


    const [selectedMode, setSelectedMode] = useState('');
    const handleModeSelect = (mode) => {
        setSelectedMode(mode);
    };

    return (
        <GameModeContext.Provider value={{ selectedMode, handleModeSelect }}>

        {children}
        </GameModeContext.Provider>
    );

}