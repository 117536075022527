

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button ,useTheme ,useMediaQuery} from '@mui/material';
import './how-to-play.css'
export function HowToPlay({open,handleClose}){
    const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
return(
    <Dialog open={open} onClose={handleClose} fullScreen = {fullScreen}>
      <DialogTitle><h1>How to Play</h1></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h2>Overview</h2>
            Hakenbush is a two-player game played on a graph with edges colored red and blue. Each player can remove edges of their own color. The game is played on a single connected graph.

            <h2>Objective</h2>
            The goal of the game is to avoid being the player who cannot make a move. The game ends when no more edges can be removed without disconnecting the remaining graph.

            <h2>Game Play</h2>
            <ol>
              <li><strong>Player Turns:</strong> Players take turns. The player playing with red edges goes first.</li>
              <li><strong>Removing Edges:</strong> On their turn, a player removes one edge of their color from the graph.</li>
              <li><strong>Disconnection:</strong>  When an edge is removed, any part of the graph that is no longer connected to the ground also gets removed.</li>
              <li><strong>Winning the Game:</strong> The game continues until one player cannot make a move. The player who cannot make a move loses the game.</li>
            </ol>


            <h2>Strategies</h2>
        <ul>
            <li><strong>Plan Ahead:</strong> Think about the future implications of your move. Consider how removing an edge will affect the remaining structure of the graph.</li>
            <li><strong>Force Moves:</strong> Try to force your opponent into a position where they have fewer options or must make a move that benefits you.</li>
            <li><strong>Maintain Balance:</strong> Aim to maintain a balanced graph to keep your options open.</li>
        </ul>

            <h2>End of Game</h2>
            <ul>
            <li>The game ends when a player cannot make a move.</li>
            <li>The player unable to make a move loses the game.</li>
            </ul>
          </DialogContentText>
        </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
)

}