import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {CircularProgress} from "@mui/material";

export default function LoadingIcon() {
    return (
        <Dialog open={true}>
            <DialogTitle>Computer's Turn</DialogTitle>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
}