import React from 'react';
import  './win-table.css'
const WinTable = ({ gameResult }) => {

    return (
        <div className="win-table-container">
            <h2>Win Table</h2>
            <table className="win-table">
                <thead>
                <tr>
                    <th>Player</th>
                    <th>Wins</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>X</td>
                    <td>{gameResult.XWinCount}</td>
                </tr>
                <tr>
                    <td>O</td>
                    <td>{gameResult.OWinCount}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default WinTable;
