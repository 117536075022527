const figureNodes1 = [
    { id: 1, color: 'black', x: 100, y: 400 },
    { id: 2, color: 'black', x: 200, y: 400 },
    { id: 3, color: 'black', x: 300, y: 400 },
    { id: 4, color: 'black', x: 400, y: 400 },
    { id: 5, color: 'black', x: 500, y: 400 },
    { id: 6, color: 'black', x: 150, y: 300 },
    { id: 7, color: 'black', x: 250, y: 300 },
    { id: 8, color: 'black', x: 350, y: 300 },
    { id: 9, color: 'black', x: 450, y: 300 },
    { id: 10, color: 'black', x: 200, y: 200 },
    { id: 11, color: 'black', x: 400, y: 200 },
  ]

  const figureEdges1 = [
    { from: 1, to: 6, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 2, to: 6, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 2, to: 7, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 3, to: 7, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 3, to: 8, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 4, to: 8, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 4, to: 9, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 5, to: 9, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 6, to: 10, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 7, to: 10, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 8, to: 11, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 9, to: 11, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
  ]


  const figureNodes2 = [

    //H Letter nodes
    { id: 1, color: 'black', x: 200, y: 200 },
    { id: 2, color: 'black', x: 100, y: 200 },
    { id: 3, color: 'black', x: 100, y: 300 },
    { id: 4, color: 'black', x: 200, y: 300 },
    { id: 5, color: 'black', x: 200, y: 400 },
    { id: 6, color: 'black', x: 100, y: 400 },

  //A letter nodes
    { id: 7, color: 'black', x: 250, y: 400 },
    { id: 8, color: 'black', x: 350, y: 400 },
    { id: 9, color: 'black', x: 275, y: 300 },
    { id: 11, color: 'black', x: 325, y: 300 },
    { id: 12, color: 'black', x: 300, y: 200 },

    //K letter
    { id: 13, color: 'black', x: 400, y: 400 },
    { id: 14, color: 'black', x: 500, y: 400 },
    { id: 15, color: 'black', x: 400, y: 300 },
    { id: 16, color: 'black', x: 400, y: 200 },
    { id: 17, color: 'black', x: 500, y: 200 },


    //E Letter nodes

    { id: 18, color: 'black', x: 550, y: 400 },
    { id: 19, color: 'black', x: 650, y: 400 },
    { id: 20, color: 'black', x: 550, y: 300 },
    { id: 10, color: 'black', x: 650, y: 300 },
    { id: 21, color: 'black', x: 550, y: 200 },
    { id: 22, color: 'black', x: 650, y: 200 },
    


    //N Letter Nodes

    { id: 23, color: 'black', x: 700, y: 400 },
    { id: 24, color: 'black', x: 800, y: 400 },
    { id: 25, color: 'black', x: 700, y: 200 },
    { id: 26, color: 'black', x: 800, y: 200 },


    //B Letter Nodes

    { id: 27, color: 'black', x: 850, y: 400 },
    { id: 28, color: 'black', x: 950, y: 400 },
    { id: 29, color: 'black', x: 950, y: 300 },
    { id: 30, color: 'black', x: 850, y: 300 },
    { id: 31, color: 'black', x: 850, y: 200 },
    { id: 32, color: 'black', x: 950, y: 200 },

    //U letter
    { id: 33, color: 'black', x: 1000, y: 400 },
    { id: 34, color: 'black', x: 1100, y: 400 },
    { id: 35, color: 'black', x: 1000, y: 200 },
    { id: 36, color: 'black', x: 1100, y: 200 },


    //S Letter Nodes

    { id: 37, color: 'black', x: 1150, y: 400 },
    { id: 38, color: 'black', x: 1250, y: 400 },
    { id: 39, color: 'black', x: 1150, y: 300 },
    { id: 40, color: 'black', x: 1250, y: 300 },
    { id: 41, color: 'black', x: 1150, y: 200 },
    { id: 42, color: 'black', x: 1250, y: 200 },


    //H Letter Nodes

    
    { id: 43, color: 'black', x: 1300, y: 400 },
    { id: 44, color: 'black', x: 1400, y: 400 },
    { id: 45, color: 'black', x: 1300, y: 300 },
    { id: 46, color: 'black', x: 1400, y: 300 },
    { id: 47, color: 'black', x: 1300, y: 200 },
    { id: 48, color: 'black', x: 1400, y: 200 },

  ]

  const figureEdges2 = [
    // H Letter Edges
    { from: 5, to: 4, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 6, to: 3, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 4, to: 3, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 3, to: 2, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 4, to: 1, width: 5, color: 'red', arrows: { to: { enabled: false }, } },

    // A letter edges
    { from: 7, to: 9, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 8, to: 11, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 9, to: 11, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 9, to: 12, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 11, to: 12, width: 5, color: 'red', arrows: { to: { enabled: false }, } },

    //K letter edges

    { from: 13, to: 15, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 14, to: 15, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 15, to: 16, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 15, to: 17, width: 5, color: 'red', arrows: { to: { enabled: false }, } },

    // E letter edges
    { from: 21, to: 22, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 20, to: 10, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 18, to: 19, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 18, to: 20, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 20, to: 21, width: 5, color: 'red', arrows: { to: { enabled: false }, } },

    //N letter edges

    { from: 23, to: 25, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 24, to: 25, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 24, to: 26, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },

    // //B letter edges

    { from: 27, to: 28, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 27, to: 30, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
     { from: 29, to: 28, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 29, to: 32, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 30, to: 31, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 32, to: 31, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 30, to: 29, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },

    // //U letter

    { from: 33, to: 35, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 33, to: 34, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 36, to: 34, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },

    // //S letter

    { from: 37, to: 38, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 40, to: 38, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 39, to: 40, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 39, to: 41, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
     { from: 41, to: 42, width: 5, color: 'red', arrows: { to: { enabled: false }, } },

    // // // //Last H letter
    { from: 43, to: 45, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 44, to: 46, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 45, to: 46, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 46, to: 48, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 45, to: 47, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
   

  ]

  
  const figureNodes3 = [
    { id: 1, color: 'black', x: 100, y: 100 },
    { id: 2, color: 'black', x: 200, y: 200 },
    { id: 3, color: 'black', x: 300, y: 100 },
    { id: 4, color: 'black', x: 200, y: 300 },
    { id: 5, color: 'black', x: 300, y: 400 },
    { id: 6, color: 'black', x: 100, y: 400 },
    { id: 7, color: 'black', x: 250, y: 250 },
    { id: 8, color: 'black', x: 300, y: 200 },
    { id: 9, color: 'black', x: 150, y: 250 },
    { id: 10, color: 'black', x: 100, y: 200 },
  ]

  const figureEdges3 = [
    { from: 2, to: 1, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 2, to: 3, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 1, to: 3, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 4, to: 2, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 5, to: 4, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 6, to: 4, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 7, to: 8, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 2, to: 7, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
    { from: 2, to: 9, width: 5, color: 'red', arrows: { to: { enabled: false }, } },
    { from: 9, to: 10, width: 5, color: 'blue', arrows: { to: { enabled: false }, } },
  ]


    
  const figureNodes4 = [

    { id: 5, color: 'black', x: 300, y: 400 },
    { id: 6, color: 'black', x: 100, y: 400 },
    { id: 7, color: 'black', x: 300, y: 250 },
    { id: 8, color: 'black', x: 100, y: 250 },
    { id: 9, color: 'black', x: 0, y: 250 },
    { id: 10, color: 'black', x: 400, y: 250 },
    { id: 11, color: 'black', x: 100, y: 150 },
    { id: 12, color: 'black', x: 300, y: 150 },

    { id: 13, color: 'black', x: 150, y: 300 },
    { id: 14, color: 'black', x: 250, y: 300 },
    { id: 15, color: 'black', x: 150, y: 400 },
    { id: 16, color: 'black', x: 250, y: 400 },

  ]

  const figureEdges4 = [
    { from: 5, to: 16, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 16, to: 15, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
    { from: 15, to: 6, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 7, to: 5, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 6, to: 8, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
   { from: 8, to: 9, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
    { from: 7, to: 10, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
    { from: 7, to: 8, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 11, to: 12, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
    { from: 12, to: 10, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 9, to: 11, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },

    { from: 13, to: 14, width: 5, color: 'blue',  arrows: { to: { enabled: false }, } },
    { from: 13, to: 15, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
    { from: 16, to: 14, width: 5, color: 'red',  arrows: { to: { enabled: false }, } },
  ]


  // This is for X line
  const xlineNodes = [
    { id: 1, color: 'black', x: 0, y: 0 },
    { id: 2, color: 'black', x: 1000, y: 0 },
  ]

  const xlineEdge = [
    { from: 1, to: 2, width: 10, color: 'blue',  arrows: { to: { enabled: false }, } },
  ]

  export {figureEdges1,figureEdges2,figureNodes1,figureNodes2,figureNodes3,figureEdges3,figureEdges4,figureNodes4,xlineEdge,xlineNodes}