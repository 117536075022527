// BattleShip.tsx

import React, { useEffect, useState } from 'react';
import './BattleShip.css';
import NavBar from "../../navbar/navbar";
import OpponentShips from './opponent-ship-status';
import LoadingIcon from '../Hackenbush/LoadingIcon';

const SHIPS = [
    { id: 1, size: 5 },
    { id: 2, size: 4 },
    { id: 3, size: 3 },
    { id: 4, size: 3 },
    { id: 5, size: 2 },
];

const GAMESTATE = Object.freeze({
    PLAYER: Symbol('player'),
    OPPONENT: Symbol('opponent'),
    INIT: Symbol('init'),
    END: Symbol('end')
})

function BattleShip() {
    const [playerGrid, setPlayerGrid] = useState([]);
    const [opponentGrid, setOpponentGrid] = useState([]);
    const [playerTurn, setPlayerTurn] = useState(GAMESTATE.INIT);


    useEffect(() => {
        placeShipsRandomly()
        placeShipsRandomlyForOpponent()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (playerTurn === GAMESTATE.OPPONENT) {
                const randomIndex = Math.floor(Math.random() * 100)
                handleOpponentTurn(playerGrid[randomIndex], randomIndex)
            }
        }, 1000
        )
    }, [playerTurn])

    useEffect(() => {
        doesComputerWon()
    }, [playerGrid]);

    useEffect(() => {
        doesPlayerWon()
    }, [opponentGrid]);

    const placeShipsRandomly = () => {
        const newGrid = Array.from({ length: 100 }).fill(null);
        SHIPS.forEach(ship => {
            let isPlaced = false;
            while (!isPlaced) {
                const direction = Math.random() < 0.5 ? 'horizontal' : 'vertical';
                const row = Math.floor(Math.random() * 10);
                const col = Math.floor(Math.random() * 10);
                if (isValidPlacement(row, col, direction, ship.size, newGrid)) {
                    placeShip(row, col, direction, ship.size, ship.id, newGrid);
                    isPlaced = true;
                }
                else {
                    isPlaced = false;
                }
            }
        });
        setPlayerGrid(newGrid);
    };
    const placeShipsRandomlyForOpponent = () => {
        const newGrid = Array.from({ length: 100 }).fill(null);
        SHIPS.forEach(ship => {
            let isPlaced = false;
            while (!isPlaced) {
                const direction = Math.random() < 0.5 ? 'horizontal' : 'vertical';
                const row = Math.floor(Math.random() * 10);
                const col = Math.floor(Math.random() * 10);
                if (isValidPlacement(row, col, direction, ship.size, newGrid)) {
                    placeShip(row, col, direction, ship.size, ship.id, newGrid);
                    isPlaced = true;
                }
            }
        });
        setOpponentGrid(newGrid);
    };

    const isValidPlacement = (startRow, startCol, direction, size, grid) => {
        if (direction === 'horizontal') {
            if (startCol + size > 10) return false;
            for (let i = startCol; i < startCol + size; i++) {
                if (grid[startRow * 10 + i] !== null) return false;
            }
        } else {
            if (startRow + size > 10) return false;
            for (let i = startRow; i < startRow + size; i++) {
                if (grid[i * 10 + startCol] !== null) return false;
            }
        }
        return true;
    };

    const placeShip = (startRow, startCol, direction, size, shipId, grid) => {
        if (direction === 'horizontal') {
            for (let i = startCol; i < startCol + size; i++) {
                grid[startRow * 10 + i] = shipId;
            }
        } else {
            for (let i = startRow; i < startRow + size; i++) {
                grid[i * 10 + startCol] = shipId;
            }
        }
    };

    const handleRandomizeClick = () => {
        placeShipsRandomly();
    };



    const startGame = () => {
        setPlayerTurn(GAMESTATE.PLAYER)
    }

    const restartGame = () => {
        setPlayerTurn(GAMESTATE.INIT)
        setPlayerGrid([])
        setOpponentGrid([])
        placeShipsRandomly()
        placeShipsRandomlyForOpponent()
        startGame()
    }

    const handleOpponentTurn = (cell, index) => {
        if (cell === 'hit' || cell === 'miss') {
            const randomIndex = Math.floor(Math.random() * 100)
            handleOpponentTurn(playerGrid[randomIndex], randomIndex)
        }
        else if (cell !== null) {
            setPlayerGrid(prevGrid => {
                const updatedGrid = [...prevGrid];
                updatedGrid[index] = 'hit';
                return updatedGrid;
            });
            let randomIndex
            if (index + 10 < 100) {
                randomIndex = index + 10;
            }
            else if (index - 10 >= 0) {
                randomIndex = index - 10;
            }
            else if (index - 1 >= 0) {
                randomIndex = index - 1;
            }
            else if (index + 1 < 100) {
                randomIndex = index + 1;
            }
            else {
                randomIndex = Math.floor(Math.random() * 100)
            }
            handleOpponentTurn(playerGrid[randomIndex], randomIndex)
        }
        else {
            setPlayerGrid(prevGrid => {
                const updatedGrid = [...prevGrid];
                updatedGrid[index] = 'miss';
                return updatedGrid;
            });
            setPlayerTurn(GAMESTATE.PLAYER)
        }
    }

    const doesComputerWon = () => {
        let gameOver = true;
        if (playerGrid.length > 0) {
            SHIPS.forEach(x => {
                const shipsExists = playerGrid.some(s => {
                    return s === x.id
                })
                if (shipsExists === true) {
                    gameOver = false;
                }
            })
        } else {
            gameOver = false;
        }
        if (gameOver === true) {
            alert('Computer Won')
            setPlayerTurn(GAMESTATE.END)
        }
    }

    const doesPlayerWon = () => {
        let gameOver = true;
        if (opponentGrid.length > 0) {
            SHIPS.forEach(x => {
                const shipsExists = opponentGrid.some(s => s === x.id)
                if (shipsExists === true) {
                    gameOver = false;
                }
            })
        } else {
            gameOver = false;
        }
        if (gameOver) {
            setPlayerTurn(GAMESTATE.END)
            alert('You Won')
        }
    }


    const handleClickGridCell = (cell, index) => {
        if (cell !== null) {
            setOpponentGrid(prevGrid => {
                const updatedGrid = [...prevGrid];
                updatedGrid[index] = 'hit';
                return updatedGrid;
            });
        }
        else {
            setOpponentGrid(prevGrid => {
                const updatedGrid = [...prevGrid];
                updatedGrid[index] = 'miss';
                return updatedGrid;
            });
            setPlayerTurn(GAMESTATE.OPPONENT)
        }
    }

    const getBackGroundColor = (shipId) => {
        switch (shipId) {
            case 1:
                return '#f4e3b2';
            case 2:
                return '#b7410e';
            case 3:
                return '#ffbf00';
            case 4:
                return '#5a352a';
            case 5:
                return '#8b4513';
            default:
                return 'white'; // Default color
        }
    }

    return (
        <>
   <>
            <NavBar game={{ name: 'BATTLESHIP', started: false }}></NavBar>
            {playerTurn === GAMESTATE.OPPONENT && <LoadingIcon />}
            <div className="BattleShip">
                <div className="player-grid-container">
                    <div className="grid player-grid">
                        {playerGrid.map((cell, index) => (
                            <div key={index} className={`cell ${cell !== null ? 'occupied' : ''} ${cell === 'miss' ? 'miss' : ''}`}>
                                {cell != null && (cell !== 'hit' && cell !== 'miss') &&
                                    <div className="ship" style={{ backgroundColor: getBackGroundColor(cell) }}></div>}
                                {cell === 'hit' && <div className="cross-mark">X</div>}
                            </div>
                        ))}
                    </div>
                    <div className="grid-label">Your Grid</div>
                </div>
                <div className="opponent-grid-container" style={{ position: 'relative', opacity: playerTurn === GAMESTATE.OPPONENT ? 0.5 : 1 }}>
                    <div className="grid opponent-grid">
                        {opponentGrid.map((cell, index) => (
                            <div onClick={() => playerTurn === GAMESTATE.PLAYER && handleClickGridCell(cell, index)}
                                key={index} className={`cell ${cell !== null ? 'occupied' : ''} ${cell === 'miss' ? 'miss' : ''}`}>
                                {cell === 'hit' && <div className="cross-mark">X</div>}
                            </div>
                        ))}
                    </div>
                    <div className="grid-label">Opponent's Grid</div>
                </div>
                <div className="buttons-container">
                    {playerTurn === GAMESTATE.INIT && (
                        <>
                            <button className="randomize-button" onClick={handleRandomizeClick}>
                                Randomize
                            </button>
                            <button className="randomize-button" onClick={startGame}>
                                Start
                            </button>
                        </>
                    )}
                    {playerTurn === GAMESTATE.END && (
                        <button className="randomize-button" onClick={restartGame}>
                            Restart
                        </button>
                    )}
                    {(playerTurn === GAMESTATE.PLAYER || playerTurn === GAMESTATE.OPPONENT) && (
                        <OpponentShips SHIPS={SHIPS} opponentGrid={opponentGrid}></OpponentShips>
                    )}
                </div>
            </div>
        </>
        </>

    );
}

export default BattleShip;
