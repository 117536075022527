import React, {useContext, useEffect, useState} from 'react';
import './tik-tak-toe.css'
import WinnerDialog from './winner-dialog';
import NavBar from '../../navbar/navbar';
import {Grid} from '@mui/material';
import PlayerSelectionDialog from "../Hackenbush/playerModeSelectionDialog";
import {GameModeContext} from "../Hackenbush/Provider/GameModeProvider";
import WinTable from "./win-table";
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';
import LoadingIcon from '../Hackenbush/LoadingIcon';

function Square({value, onSquareClick}) {
    return (
        <button className="square" onClick={onSquareClick}>
            {value}
        </button>
    );
}

function Board({xIsNext, squares, onPlay ,gameTurn}) {
        const {selectedMode, handleModeSelect} = useGameMode();
    function handleClick(i) {
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        const nextSquares = squares.slice();
        if (xIsNext) {
            nextSquares[i] = 'X';
        } else {
            nextSquares[i] = 'O';
        }
        onPlay(nextSquares);
    }

    const winner = calculateWinner(squares);
    let status;
    if (winner != null && winner === 'draw') {
        status = "It's a Draw!"
    } else if (winner != null) {
        status = 'Winner: ' + winner;
    } else {
        if(selectedMode === 'MACHINE'){
            status =   !(gameTurn =='COMPUTER') ? 'Your turn: X' : 'Computer turn: O';
        }
        else{
        status = 'Next player: ' + (xIsNext ? 'X' : 'O');
        }
    }


    return (
        <>
            <div className="status">{status}</div>
            <div className="board-row">
                <Square value={squares[0]} onSquareClick={() => handleClick(0)}/>
                <Square value={squares[1]} onSquareClick={() => handleClick(1)}/>
                <Square value={squares[2]} onSquareClick={() => handleClick(2)}/>
            </div>
            <div className="board-row">
                <Square value={squares[3]} onSquareClick={() => handleClick(3)}/>
                <Square value={squares[4]} onSquareClick={() => handleClick(4)}/>
                <Square value={squares[5]} onSquareClick={() => handleClick(5)}/>
            </div>
            <div className="board-row">
                <Square value={squares[6]} onSquareClick={() => handleClick(6)}/>
                <Square value={squares[7]} onSquareClick={() => handleClick(7)}/>
                <Square value={squares[8]} onSquareClick={() => handleClick(8)}/>
            </div>
        </>
    );
}


const useGameMode = () => useContext(GameModeContext);
export default function TikTakToe() {
    const {selectedMode, handleModeSelect} = useGameMode();
    const [history, setHistory] = useState([Array(9).fill(null)]);
    const [openWinnerDialog, setOpenWinnerDialog] = useState(false);
    const [winner, setWinner] = useState(null);
    const [isDialogOpen, handleGameModeDialogVisibility] = useState(false);
    const [gameTurn, setGameTurn] = useState('')

    const [currentMove, setCurrentMove] = useState(0);
    const [gameResult, setGameResult] = useState({XWinCount: 0, OWinCount: 0})
    const xIsNext = currentMove % 2 === 0;
    const currentSquares = history[currentMove];
    const navigate = useNavigate();

    useEffect(() => {
        handleGameModeDialogVisibility(true);
    }, []);

    useEffect(() => {
        if (selectedMode === 'MACHINE') {
            setGameTurn('HUMAN');
        }
        if (selectedMode === 'HUMAN') {
            setGameTurn('HUMAN')
        }
    }, [selectedMode]);

    useEffect(() => {
        if (gameTurn === 'COMPUTER') {
            if (calculateWinner(history[currentMove]) == null) {
                computerMove();
            }
        }
    }, [gameTurn])


    function computerMove() {
        let bestScore = -Infinity;
        let bestMove = null;
    
        // Check if it's the first move
        if (currentMove === 0) {
            const randomMove = Math.floor(Math.random() * 9);
            let newArray = [...history[currentMove]];
            newArray[randomMove] = 'O'; // Assume computer is 'X'
            handlePlay(newArray);
            setGameTurn('HUMAN');
            return;
        }
    
        // Rest of the computer move logic for subsequent moves
        for (let i = 0; i < 9; i++) {
            if (history[currentMove][i] == null) {
                let newArray = [...history[currentMove]];
                newArray[i] = 'O'; // Assume computer is 'X'
                let score = minimax(newArray, false);
                if (score > bestScore) {
                    bestScore = score;
                    bestMove = i;
                }
            }
        }
    setTimeout(() => {
        if (bestMove !== null) {
            let newArray = [...history[currentMove]];
            newArray[bestMove] = 'O';
            handlePlay(newArray);
            setGameTurn('HUMAN');
        }
    },1000)

    }
    
    function minimax(board, maximizingPlayer) {
        let result = calculateWinner(board);
        if (result !== null) {
            if (result === 'O') {
                return 1; // Computer wins
            } else if (result === 'X') {
                return -1; // Human wins
            } else {
                return 0; // Draw
            }
        }
    
        if (maximizingPlayer) {
            let bestScore = -Infinity;
            for (let i = 0; i < board.length; i++) {
                if (board[i] === null) {
                    board[i] = 'O';
                    let score = minimax(board, false);
                    board[i] = null;
                    bestScore = Math.max(score, bestScore);
                }
            }
            return bestScore;
        } else {
            let bestScore = Infinity;
            for (let i = 0; i < board.length; i++) {
                if (board[i] === null) {
                    board[i] = 'X';
                    let score = minimax(board, true);
                    board[i] = null;
                    bestScore = Math.min(score, bestScore);
                }
            }
            return bestScore;
        }
    }

    function handlePlay(nextSquares) {
        const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];
        setHistory(nextHistory);
        setCurrentMove(nextHistory.length - 1);
        if (selectedMode === 'MACHINE') {
            setGameTurn('COMPUTER');
        }

        const gameWinner = calculateWinner(nextSquares);
        if (gameWinner !== null) {
            if (gameWinner === 'draw') {
                setWinner('"It\'s a Draw!"')
            } else {
                setWinner(gameWinner + " PLAYER WON.");
                if (gameWinner === 'X') {
                    setGameResult({...gameResult, XWinCount: gameResult.XWinCount + 1})
                }
                if (gameWinner === 'O') {
                    setGameResult({...gameResult, OWinCount: gameResult.OWinCount + 1})
                }
            }
            setOpenWinnerDialog(true);
            setGameTurn('')
        }
    }

    function closeWinnerDialog() {
        setOpenWinnerDialog(false);
    }

    function resetGame() {
        setGameTurn('')
        setHistory([Array(9).fill(null)]);
        setCurrentMove(0);
        setOpenWinnerDialog(false); // Close the winner dialog
        setWinner(null); // Reset the winner
        if (selectedMode === 'MACHINE') {
            setGameTurn('HUMAN')
        }
    }

    function closeDialog() {
        handleGameModeDialogVisibility(false)
        navigate('/');
    }

    return (
        <div>
            <NavBar game={{name: 'TIC-TAK-TOE', started: false}}></NavBar>
            <Container sx={{py: 8}} maxWidth="md" className="custom-container">
            {gameTurn === 'COMPUTER' && <LoadingIcon />}

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{minHeight: 'calc(100vh - 64px)'}} // Subtract Navbar height
                >
                    <Grid item>
                        <div className="game">
                            <div className="game-board">
                                <Board xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay} gameTurn={gameTurn}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item style={{paddingLeft:'10px'}}>
                        <WinTable gameResult={gameResult}></WinTable>
                    </Grid>

                </Grid>

            </Container>

            <PlayerSelectionDialog open={isDialogOpen} onClose={closeDialog} onStart={() => handleGameModeDialogVisibility(false)}/>
            <WinnerDialog open={openWinnerDialog} onClose={closeWinnerDialog} winner={winner} onReset={resetGame}/>
        </div>
    );
}

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }

    if (!squares.includes(null)) {
        return 'draw'; // All squares are filled and there's no winner
    }
    return null;
}
